import React, { useState } from "react";
import Heading from "../components/Heading";
import ImageComponent from "../components/ImageComponent";
import ScrollDiv from '../components/Scroll';
import { Div } from "../../../style/styledElements/StyledElements";

const ForgotPassword = ({ direction = 'column' }) => {

    const [column, setColumn] = useState(direction === 'column');
    const steps = [
        {
            id: 7,
            para: 'In case the participant forgets their password, click on the "Forgot Password?"',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-26/0dd55d50-ae72-4802-a42e-e5d199a4e54f/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,869&force_format=png&width=1120.0"
        },
        {
            id: 8,
            para: 'Enter the Registered Email id to get the OTP.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-26/86305a96-3a65-4290-a0cd-bf1016ca0c28/ascreenshot.jpeg?tl_px=0,0&br_px=1920,869&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=433,88"
        },
        {
            id: 9,
            para: 'Click on "Submit" to get the OTP.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-26/9aa2e86a-ea85-44c4-8c82-308eb512d2ee/ascreenshot.jpeg?tl_px=0,0&br_px=1920,869&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=489,120"
        },
        {
            id: 10,
            para: 'The Email Confirmation for the "Forgot Password" request has been sent to the participant\'s Email.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-26/6a471a1a-1d68-4e7c-82d6-fd9f8918c795/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,869&force_format=png&width=1120.0"
        },
        {
            id: 11,
            para: 'By clicking on "Click here", participant can confirm the Email Id.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-26/779713aa-7e17-492f-8011-2426c44e173b/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,869&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=231,316"
        },
        {
            id: 12,
            para: 'After confirming the Email, the password is changed, and an email is sent to the participant.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-26/7d42dba7-0f75-4e1d-a096-b6779539ee14/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,861&force_format=png&width=1120.0"
        },
        {
            id: 13,
            para: 'Here, Participant get a temporary password to login.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-26/09c496e0-9b23-4554-bdb2-07b670575146/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,870&force_format=png&width=1120.0"
        },
        {
            id: 14,
            para: 'Participant can login with the temporary password.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-26/1a7e3d1a-9f43-4294-a350-f8f4a8bb9482/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,869&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=360,302"
        },
        {
            id: 15,
            para: 'After the successful Login Participant will be Landed to the Dashboard Page.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-13/c9fb3a3a-6a62-453f-be40-43ea9f6d4809/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0"
        }
    ];

    return (
        <Div bg={column && '#F3F2F2'} width='95%' borderRadius='32px' pb='6'>
            <Div display={direction !== 'row' && 'none'} >
                <Heading text='Forgot Password' numberOfScreens={steps.length} />
            </Div>
            <ScrollDiv>
                <Div className='scroll' style={{ display: 'flex', flexDirection: direction, overflowX: 'auto', alignItems: 'center' }}>
                    <Div py='4' width='60%' display={direction === 'row' && 'none'}>
                        <Heading mb='0' text='Forgot Password' numberOfScreens={steps.length} />
                    </Div>
                    {steps.map(step => (
                        <Div py='4' key={step.id} style={{ marginRight: '20px', width: '60%' }}>
                            <ImageComponent imageUrl={step.imageUrl} direction={direction} desc={step.para} />

                        </Div>
                    ))}
                </Div>
            </ScrollDiv>
        </Div>
    );
};

export default ForgotPassword;

import styled from 'styled-components';
import { Div } from '../../../style/styledElements/StyledElements';

const ScrollDiv = styled(Div)`

.scroll::-webkit-scrollbar {
width: 5px; /* Width of the scrollbar */
height: 10px; /* Height of the scrollbar track */
}

.scroll::-webkit-scrollbar-thumb {
background-color: #577CFF; /* Color of the scrollbar thumb */
border-radius: 10px; /* Border radius of the scrollbar thumb */
}
`;

export default ScrollDiv;

import React from "react";
import { Div, Flex, Span } from "../style/styledElements/StyledElements";
import { ReactComponent as Tick } from "../asset/icons/Tick.svg";
import { ReactComponent as Location } from "../asset/icons/Location.svg";
import { ReactComponent as Education } from "../asset/icons/Education.svg";
import { ReactComponent as Mandatory } from "../asset/icons/Mandatory.svg";
import { ReactComponent as CourseExpiry } from "../asset/icons/CourseExpiry.svg";
import Circle from "react-circle";

function MandatoryCard(props) {
    const {
        title,
        completionStatus,
        credits,
        progress,
        location,
        expiry,
        ...rest
    } = props;

    return (
        <Div
            boxShadow='0px 3px 8px rgba(74, 85, 104, 0.07)'
            borderRadius='20px'
            p='3'
            fontSize='1'
            bg='white'
            cursor='pointer'
            {...rest}
        >
            <Flex justifyContent='space-between'>
                <Div width='75%'>
                    <Div
                        fontWeight='500'
                        mb='2'
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            lineHeight: "1",
                        }}
                    >
                        {title}
                    </Div>

                    <Flex color='rgba(42, 172, 142, 0.7)'>
                        <Tick />
                        <Div ml='2' pt='2px'>
                            {completionStatus}
                        </Div>
                    </Flex>

                    <Flex mt='2' fontSize='1' alignItems='center'>
                        <Education />
                        <Span ml='2'>{credits} Credits</Span>
                    </Flex>
                    <Flex
                        height='25px'
                        borderRadius='8px'
                        border='1px solid #F2F4F7'
                        fontSize='1'
                        fontWeight='500'
                        mt='8px'
                        alignItems='center'
                        pl='5px'
                    >   
                        <Location />
                        <Span ml='2'>{location}</Span>
                    </Flex>
                </Div>
                <Span width='25%'>
                    <Circle
                        progress={progress}
                        progressColor='#FF9878'
                        bgColor='#E3E4E8'
                        roundedStroke
                        textColor='#050D2E'
                        lineWidth={30}
                        textStyle={{
                            font: "500 5rem Inter",
                        }}
                        responsive
                    />
                </Span>
            </Flex>
            <Div>
                <Flex
                    height='37px'
                    color='#4b0e0e'
                    padding='10px 20px'
                    borderRadius='20px'
                    border='1px solid #ffb3b3'
                    boxShadow='0 2px 4px rgba(0, 0, 0, 0.1)'
                    fontSize='1'
                    fontWeight='bold'
                    mt='8px'
                    alignItems='center'
                    justifyContent='center'
                >
                    <Mandatory />
                    <Span ml='2'>Mandatory Course</Span>
                </Flex>
                <Flex color='#FF6565' mt='8px' alignItems='center'>
                    <CourseExpiry />
                    <Span ml='2' fontWeight='bold'>
                        Course Expiry :
                    </Span>
                    <Div ml='2' color='#A0A9B4' fontWeight='600' fontSize='1'>
                        {expiry}
                    </Div>
                </Flex>
            </Div>
        </Div>
    );
}

export default MandatoryCard;

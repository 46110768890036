import React from 'react';
import { Col, Div, Flex } from '../../style/styledElements/StyledElements';

function SummaryCard({
    Icon,
    name,
    completedCount,
    onProgressCount,
    color,
}) {
    return (
        <Flex {...styles.card}>
            <Flex center bg={color} {...styles.avatar} width='25%'>
                {Icon && <Icon />}
            </Flex>
            <Col pl='4' pr = '6' width='75%'>
                <Div fontWeight='600' width='100%'>
                    {name}
                </Div>
                <Flex mt='3'>
                    <Col width='50%' fontSize='1' color='#2AAF60'>
                        Completed
                    </Col>
                    <Col width='20%' textAlign='center' color='#2AAF60'>
                        :
                    </Col>
                    <Col width='30%' fontWeight='500' textAlign='end'>
                        {completedCount}
                    </Col>
                </Flex>
                <Flex mt='1'>
                    <Col width='50%' fontSize='1'>
                        On Progress
                    </Col>
                    <Col width='20%' textAlign='center'>
                        :
                    </Col>
                    <Col width='30%' fontWeight='500' textAlign='end'>
                        {onProgressCount}
                    </Col>
                </Flex>
            </Col>
        </Flex>
    );
}

export default SummaryCard;

const styles = {
    card: {
        mb: '3',
        p: '3',
        bg: 'white',
        borderRadius: '5',
        boxShadow: '0px 3px 8px rgba(74, 85, 104, 0.07)',
    },
    avatar: {
        borderRadius: '5',
    },
};

import React, { useState } from 'react';
import endpoints from '../../endpoints';
import {
    Col,
    Div,
    Flex,
    H2,
    Hr,
    Img,
    Span,
} from '../../style/styledElements/StyledElements';
import Loader from '../../components/Loader';
import Topper from './Topper';
import { useEffect } from 'react';
import axios from 'axios';
import Avatar from '../../asset/icons/UserAvatar.svg';
import { ReactComponent as Crown } from '../../asset/icons/Crown.svg';

function LeaderBoard() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        axios({
            method: 'get',
            url: endpoints.profile.leaderBoard,
        })
            .then((res) => {
                setData(res.data.responseData);
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <Loader />;
    }

    const remainingRanks = [...data];
    const topRanks = remainingRanks.splice(0, 3);

    const first = topRanks[0];
    const second = topRanks[1];
    const third = topRanks[2];

    return (
        <Flex justifyContent='center'>
            <Div width='55%' textAlign='center'>
                <H2>Leaderboard</H2>
                <Span color='gray' fontSize='small'>
                    Best Champions
                </Span>
                <Div px='9' mt='5'>
                    <Crown />
                    {first && (
                        <Div>
                            <Topper
                                pic={first?.DP || Avatar}
                                width='10vw'
                                rank={1}
                                height='10vw'
                                variant='#FFBB1A'
                                title={'Image of ' + first?.PARTICIPANT_NAME}
                            />
                            <Div fontWeight='600'>
                                {first?.PARTICIPANT_NAME}
                            </Div>
                            <Div fontWeight='500' color='#A37200'>
                                {first?.totalcredits} Credits
                            </Div>
                        </Div>
                    )}
                    <Flex justifyContent='space-between'>
                        {second && (
                            <Div width='fit-content'>
                                <Topper
                                    pic={second?.DP || Avatar}
                                    width='8vw'
                                    rank={2}
                                    height='8vw'
                                    variant='#577CFF'
                                    title={
                                        'Image of ' + second?.PARTICIPANT_NAME
                                    }
                                />
                                <Div fontWeight='600'>
                                    {second?.PARTICIPANT_NAME}
                                </Div>
                                <Div fontWeight='500' color='#A37200'>
                                    {second?.totalcredits} Credits
                                </Div>
                            </Div>
                        )}
                        {third && (
                            <Div width='fit-content'>
                                <Topper
                                    pic={third?.DP || Avatar}
                                    width='8vw'
                                    rank={3}
                                    height='8vw'
                                    variant='#5FB670'
                                    title={
                                        'Image of ' + third?.PARTICIPANT_NAME
                                    }
                                />
                                <Div fontWeight='600'>
                                    {third?.PARTICIPANT_NAME}
                                </Div>
                                <Div fontWeight='500' color='#A37200'>
                                    {third?.totalcredits} Credits
                                </Div>
                            </Div>
                        )}
                    </Flex>
                </Div>
                <Div mt='7'>
                    {remainingRanks.map((ranker, i) => (
                        <Flex alignItems='center' mt='4'>
                            <Col width={'10%'}>
                                <Flex
                                    display='inline-block'
                                    center
                                    width='30px'
                                    height='30px'
                                    boxShadow='0px 4px 4px rgba(0, 0, 0, 0.06)'
                                    backgroundColor='rgba(255, 255, 255, 0.2)'
                                    borderRadius='50%'
                                >
                                    {i + 4}
                                </Flex>
                            </Col>
                            <Flex alignItems='center' width={'85%'}>
                                <Topper
                                    pic={ranker?.DP || Avatar}
                                    width='70px'
                                    // rank={1}
                                    height='70px'
                                    variant='#5FB670'
                                    title={
                                        'Image of ' + ranker?.PARTICIPANT_NAME
                                    }
                                />
                                <Span ml='5'>{ranker?.PARTICIPANT_NAME}</Span>
                                <Div ml='auto' color='#A37200'>
                                    {ranker?.totalcredits} Credits
                                </Div>
                            </Flex>
                            <Hr width='90%' mt='4' mb='0' ml='auto' mr='0' />
                        </Flex>
                    ))}
                </Div>
            </Div>
        </Flex>
    );
}

export default LeaderBoard;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticationState } from '../../context/AuthContext';
import {
    Div,
    Flex,
    Link,
    Span,
} from '../../style/styledElements/StyledElements';
import { ReactComponent as Logout } from '../../asset/icons/Logout.svg';
import { ReactComponent as Trophy } from '../../asset/icons/Trophy.svg';
import Search from '../search/Index';
import Notification from '../notification/Notification';
import avatar from '../../asset/icons/UserAvatar.svg';
import { SearchProvider } from '../../context/SearchContext';

function Nav({ notifications, refreshNotification, searchData }) {
    const { user, logout } = useAuthenticationState();
    const navigate = useNavigate();

    return (
        <Div
            width='calc(100% - 100px)'
            top='0'
            boxShadow='2px 1px 4px rgba(0, 0, 0, 0.1)'
            ml='100px'
            zIndex='1000'
            position='fixed'
        >
            <Flex alignItems='center' bg='white' height='80px' px='6'>
                <Search
                // data={searchData}
                />

                <Notification
                    notifications={notifications}
                    refresh={refreshNotification}
                />
                <Link to='leader-board'>
                    <Flex
                        border='1px solid #EFEEEE'
                        height='50px'
                        width='50px'
                        center
                        borderRadius='4'
                        boxShadow='5px 6px 7px rgb(41 72 123 / 15%)'
                        mr='6'
                    >
                        <Trophy />
                    </Flex>
                </Link>

                <Flex
                    onClick={() => navigate('profile')}
                    mr='4'
                    cursor='pointer'
                >
                    {!user.dp ? (
                        <img height='48px' width='48px' src={avatar} />
                    ) : (
                        <Div
                            // border='1px solid lightgray'
                            height='48px'
                            width='48px'
                            borderRadius='50%'
                            backgroundImage={'url(' + encodeURI(user.dp) + ')'}
                            backgroundSize='cover'
                            backgroundPosition='center'
                        />
                    )}
                    <Flex flexDirection='column' ml='3' alignSelf='center'>
                        <Span
                            mr='4'
                            mb='1'
                            fontWeight='500'
                            to='profile'
                            textTransform='capitalize'
                            fontSize='2'
                        >
                            {user.name?.toLowerCase()}
                        </Span>
                        <Span fontSize='11px' color='gray'>
                            {user.email}
                        </Span>
                    </Flex>
                </Flex>
                <Span onClick={logout} cursor='pointer'>
                    <Logout />
                </Span>
            </Flex>
            {/* <LoaderNav /> */}
        </Div>
    );
}

export default Nav;

import React, { useState } from "react";
import Heading from "../components/Heading";
import ImageComponent from "../components/ImageComponent";
import ScrollDiv from '../components/Scroll';
import { Div } from "../../../style/styledElements/StyledElements";

export const RateReview = ({ direction = 'column' }) => {

    const [column, setColumn] = useState(direction === 'column');
    const steps = [
        {
            id: 43,
            para: 'After completing the course by 50% Participant can see "Rate and Review" section......',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-06/985cf822-bb1a-4280-b662-327d937e01a1/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0"
        },
        {
            id: 44,
            para: 'Click on the stars to Rate the Course.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-06/895510cc-b411-4203-99b0-fd5b33f8be84/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0"
        },
        {
            id: 45,
            para: 'To give a Feedback to the course Enter the feedback in the highlighted portion.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-06/1f70b48d-3ce5-466d-b01c-4b542438545c/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=914,312"
        },
        {
            id: 46,
            para: 'Click on "Submit" to Submit the Rating and Feedback.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-06/6d945005-1d82-4a39-b445-81db4d6d3a07/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=911,429"
        }
    ];

    return (
        <Div bg={column && '#F3F2F2'} width='95%' borderRadius='32px' pb='6'>
            <Div display={direction !== 'row' && 'none'} >
                <Heading text='Rating And Review' numberOfScreens={steps.length} />
            </Div>            <ScrollDiv>
                <div className='scroll' style={{ display: 'flex', flexDirection: direction, overflowX: 'auto', alignItems: 'center' }}>
                    <Div py='4' width='60%' display={direction === 'row' && 'none'}>
                        <Heading mb='0' text='Rating And Review' numberOfScreens={steps.length} />
                    </Div>
                    {steps.map(step => (
                        <Div py='4' key={step.id} style={{ marginRight: '20px', width: '60%' }}>
                            <ImageComponent imageUrl={step.imageUrl} direction={direction} desc={step.para} />
                        </Div>
                    ))}
                </div>
            </ScrollDiv>
        </Div>
    );
};

export default RateReview;

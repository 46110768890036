const endpoints = Object.freeze({
    auth: {
        login: 'mobile/auth/web/login',
        logout: 'mobile/auth/logout',
        token: 'mobile/auth/refresh-token',
    },
    profile: {
        activate: 'mobile/activate_user/web/activate',
        resetPassordWOtp: 'mobile/forgotpassword/web/forgot-password/reset',
        get: 'mobile/user/get/',
        profileUpdate: 'mobile/user/userupdate',
        changePassword: 'mobile/user/changePassword',
        forgetPassword: 'mobile/auth/confirm-forgot-password',
        getSignedURL: 'mobile/user/getSignedURL',
        dpUpdate: 'mobile/user/updatedp',
        completeCourses: 'mobile/courseprogram/list/coursescompleted/',
        coursesInProgress: 'mobile/courseprogram/list/coursesnotcompleted',
        reviews: 'mobile/reviewrating/allreviewlist/',
        // leaderBoard: 'mobile/admin/analytics/beststudent',
        leaderBoard: '/admin/analytics/beststudent',
    },
    notification: {
        list: 'mobile/user/studentnotification_mobile',
        read: 'mobile/user/studentnotificationread',
    },
    calendar: {
        get: 'mobile/calendar/participant',
    },
    course: {
        get: 'mobile/courseprogram/list/availablecourses',
        unregistered: 'mobile/enroll/Updated/notenrolledcourses',
        enrolled: 'mobile/enroll/course/enrolled',
        enroll: 'mobile/enroll/course/add',
        content: 'mobile/courseprogram/list/coursecontent/',
        updateStatus: 'mobile/coursestructure/item/update/coursecomplete',
        rating: 'mobile/reviewrating/rating/course/list/',
        rate: 'mobile/reviewrating/rating/course/add',
        skipRating: 'mobile/reviewrating/course/rating/skip',
        ratingBy: 'mobile/reviewrating/courserating/completelist/',
        review: 'mobile/reviewrating/review/course/add',
        outcomes: 'mobile/courseprogram/list/courseoutcomes/',
    },
    structure: {
        getEnrolled: 'mobile/coursestructure/list/',
        getUnEnrolled: 'mobile/coursestructure/completelist/',
        status: 'mobile/coursestructure/item/getstatus',
        updateStatus: 'mobile/coursestructure/item/updatestatus',
    },
    program: {
        get: 'mobile/courseprogram/list/availableprograms',
        getProgram: 'mobile/enroll/program/enrolled/program-course/',
        enrolled: 'mobile/enroll/program/enrolled',
        unregistered: 'mobile/enroll/notenrolledprogrames',
        enroll: 'mobile/enroll/program/enroll',
        courseList: 'mobile/courseprogram/list/courselisting/',
        content: 'mobile/courseprogram/list/coursecontent/',
        status: 'mobile/enroll/program/enrolled/completed/',
        statusUpdate: 'mobile/enroll/program/status/update',
        outcomes: 'mobile/courseprogram/list/programoutcomes/',
    },
    quiz: {
        get: 'mobile/quiz/list/detail/',
        quizGenerate: 'admin/updatedquiz/afterQuizCreation/list/detail',
        // quizStart: 'admin/updatedquiz/joinquiz',
        quizStart: 'admin/updatedquiz/web/joinquiz',

        submit: 'admin/updatedquiz/web/submit',
        // result: 'mobile/quiz/quizresults',
        result: 'admin/quizresult/web/secondversion/quizresults',
        resultWithTag: 'admin/updatedquiz/web/ResultParticipant',
        howToImprove: 'admin/quizresult/web/secondversion/howtoimprove',
        // howToImprove: 'admin/quizresult/secondversion/howtoimprove',
    },
    liveClass: {
        join: 'mobile/lc/join/',
        isStarted: 'mobile/lc/isRunning/',
        rate: 'mobile/lc/add/reviewrating',
        enroll: 'mobile/coursestructure/liveclass/choose',
        lcRecords:'participant/cb/liveclassmediamapping/participant/list/',
    },
    certificate: {
        applyForCourse: 'mobile/pdf/addcoursecertificate',
        applyForProgram: 'mobile/pdf/addprogramcertificate',
        getCourse: 'mobile/badge/list/coursescertificates',
        getByCourse: 'mobile/badge/list/coursescertificate/',
        getProgram: 'mobile/badge/list/programcertificates',
    },
    dashboard: {
        get: 'mobile/user/dashboard/',
    },
});
export default endpoints;

import React, { createContext, useContext, useMemo, useState } from 'react';
import endpoints from '../endpoints';
import useSearchData from '../components/search/useSearchData';

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
    const [type, setType] = useState('Courses');

    const urls = useMemo(() => {
        const urlType = type === 'Courses' ? 'course' : 'program';
        return {
            enrolled: endpoints[urlType].enrolled,
            unenrolled: endpoints[urlType].unregistered,
        };
    }, [type]);

    const { data, reload, loading } = useSearchData({ urls });

    console.log(data, 'data on context');

    const toggleType = () => {
        if (type === 'Courses') {
            setType('Programs');
        } else {
            setType('Courses');
        }
    };

    return (
        <SearchContext.Provider value={{ data, type, toggleType, reload }}>
            {loading ? null : children}
        </SearchContext.Provider>
    );
};

export const useSearch = () => {
    const context = useContext(SearchContext);
    if (!context) {
        throw new Error('useSearch must be used within a SearchProvider');
    }
    return context;
};

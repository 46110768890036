import React, { useState, useRef, useEffect } from 'react';
import { Div, Input, Span, Link, Flex } from '../../../style/styledElements/StyledElements'; // Import Input from your styled components library
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as DownArrow } from '../../../asset/icons/userGuide/chevron-down.svg'
import styled from 'styled-components';


const Button = styled(Div)`
border:1px solid #577CFF;
cursor:pointer;
border-radius:10px;
font-size: 14px;
font-weight: 400;
transition: all 0.3s ease-in;
&:hover {
    transform: scale(1.01) translate(5px, 0px) skewX(0deg);
}
`;



const ScrollDiv = styled(Div)`

.scroll::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  .scroll::-webkit-scrollbar-thumb {
    background-color: #577CFF;
    border-radius: 10px;
  }

  .scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #D0DCFFA8;
  }

`;

const links = [
    {
        path: 'login',
        name: 'Login',
        heading: 'Login'
    },
    {
        path: 'forgotPassword',
        name: 'Forgot Password',
    },
    {
        path: 'studyMaterials',
        name: 'Study Materials',
    },
    {
        path: 'course',
        name: 'Course',
        heading: 'Course'
    },
    {
        path: 'program',
        name: 'Program',
    },
    {
        path: 'liveClass',
        name: 'Schedule Live Class',
    },
    {
        path: 'get-certificate',
        name: 'Get Certificate',
    },
    {
        path: 'notification',
        name: 'Notifications',
    },
    {
        path: 'leader-board',
        name: 'Leader Board',
    },
    {
        path: 'rating-review',
        name: 'Rating & Review',
    },
    {
        path: 'logout',
        name: 'Log out',
    }
];

function SearchBar() {
    const [isClicked, setClick] = useState(false);
    const [navigationPath, setNavigationPath] = useState('');
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [searchTerm, setSearchTerm] = useState('');
    const searchBarRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
                setSearchTerm('');
                setNavigationPath('');
                setClick(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchBarRef]);


    const filteredLinks = links.filter(link =>
        link.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleRadioChange = (path) => {
        setNavigationPath(path);
    };

    const handleDropdownClick = () => {
        setClick(!isClicked);
    };

    const handleCancel = () => {
        setNavigationPath('');
        setClick(false);
        setSearchTerm('');
    };


    const handleNavigate = () => {
        if (navigationPath) {
            setClick(!isClicked);
            navigate(navigationPath);
            setNavigationPath('');
            setSearchTerm('');
            setClick(false);
        }
    };

    return (
        <Div>
            <Div
                width='77%'
                fontSize={1}
                zIndex='900'
                position='fixed'
                top='0'
                left='10'
                pt='8%'
                ml='2%'
                alignItems='center'
                ref={searchBarRef}
            >
                <Flex
                    bg='white'
                    border='1px solid #D0D5DD'
                    borderRadius='15px'
                    px='2'
                    py='10px'
                    width='100%'
                    alignItems='center'
                    boxShadow='2px 2px 2px 1px rgba(0, 0, 0, 0.1)'
                >
                    <Input

                        type="text"
                        placeholder="Search or Choose Flows"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        width='98%'
                        border='none'
                    />

                    <Span
                        cursor='pointer'
                        onClick={handleDropdownClick}
                    >
                        <DownArrow />
                    </Span>

                </Flex>
                {
                    (isClicked || searchTerm) && filteredLinks.length > 0 ? (
                        <Div
                            mt='4'
                            bg='white'
                            borderRadius='16px'
                            width='100%'
                            boxShadow='2px 2px 4px 2px rgba(0.3, 0.3, 0.3, 0.3)'
                        >
                            <Div ml='2' mr='4'>
                                <Div p='3' borderBottom='1px solid #F2F4F7' mr='4'> Flow menu </Div>
                                <ScrollDiv>
                                    <Div className='scroll' maxHeight='200px' overflow='auto' >

                                        {filteredLinks.map((link) => (
                                            <Flex p='2' key={link.path} >
                                                <label style={{ cursor: 'pointer' }}>
                                                    <input

                                                        type="radio"
                                                        name="sidebarRadio"
                                                        value={link.path}
                                                        onChange={() => handleRadioChange(link.path)}
                                                    />
                                                    {link.name}
                                                </label>
                                            </Flex>
                                        ))}
                                    </Div>
                                </ScrollDiv>
                                <Div borderTop='1px solid #F2F4F7' mr='4'>
                                    {filteredLinks.map((link) => (
                                        link.heading && (
                                            <>
                                                <Div p='2' >{link.heading}</Div>
                                                <Flex p='2' key={link.path} borderBottom='1px solid #F2F4F7'>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="sidebarRadio"
                                                            value={link.path}
                                                            onChange={() => handleRadioChange(link.path)}
                                                        />
                                                        {link.name}
                                                    </label>
                                                </Flex>
                                            </>
                                        )
                                    ))}
                                </Div>
                                <Flex p='2' px='6' justifyContent='center'>
                                    <Button textAlign='center' width='40%' bg='white' py='3' px='6' mr='5' onClick={handleCancel}>Cancel</Button>
                                    <Button textAlign='center' width='40%' bg='#577CFF' py='3' px='6' onClick={handleNavigate}>Apply</Button>

                                </Flex>
                            </Div>
                        </Div>
                    )
                        : (searchTerm &&
                            <Div
                                textAlign='center'
                                mt='4' bg='white'
                                borderRadius='16px'
                                py='4'
                                boxShadow='4px 4px 4px 4px rgba(0.3, 0.3, 0.3, 0.3)'
                            >
                                <Span >No Flows Found For The Search</Span>
                            </Div>

                        )
                }

            </Div >
        </Div>
    );
}

export default SearchBar;

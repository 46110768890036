import React from 'react';
import {
    Div,
    Flex,
    Grid,
    Span,
} from '../../style/styledElements/StyledElements';
import { ReactComponent as Schedule } from '../../asset/icons/dashboard/ScheduleBlue.svg';
import { useNavigate } from 'react-router-dom';

function LiveClass({ classes }) {
    const navigate = useNavigate();

    return (
        <Div>
            <Div fontWeight='600' my='5'>
                Today's Live classes
            </Div>
            {classes?.length === 0 ? (
                <Flex
                    center
                    border='8px solid white'
                    borderRadius='6'
                    height='250px'
                >
                    <Div textAlign='center'>
                        <Div color='#FABF35' fontSize='4' mb='3'>
                            Class room is Empty
                        </Div>
                        <Div color='gray' fontSize='4'>
                            No Classes Enrolled for Today{' '}
                        </Div>
                    </Div>
                </Flex>
            ) : (
                <Flex maxHeight='250px' overflowY='auto'>
                    {classes?.map((live) => (
                        <Grid
                            mb='5'
                            gridTemplateColumns='1fr 2fr 2fr'
                            key={live?.CALENDAR_ID}
                            width='100%'
                            pr='2'
                            onClick={() => navigate('/schedule-calendar')}
                        >
                            <Flex
                                center
                                bg='#DAF0F6'
                                width='65px'
                                height='65px'
                                borderRadius='5'
                            >
                                <Schedule />
                            </Flex>
                            <Flex flexDirection='column' mt='2' ml='3'>
                                <Span fontWeight='600' mb='2'>
                                    {live?.CALENDAR_EVENT_NAME}
                                </Span>

                                <Span fontSize='2'>
                                    {new Date(
                                        live?.CALENDAR_SCHEDULE_STARTTIME
                                    ).toLocaleDateString('en', {
                                        dateStyle: 'medium',
                                    })}
                                </Span>
                            </Flex>
                            <Span ml='auto' fontWeight='600' mt='2'>
                                {new Date(
                                    live?.CALENDAR_SCHEDULE_STARTTIME
                                ).toLocaleTimeString('en', {
                                    timeStyle: 'short',
                                })}
                            </Span>
                        </Grid>
                    ))}
                </Flex>
            )}
        </Div>
    );
}

export default LiveClass;

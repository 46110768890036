import React, { useState } from 'react';
import { Div, Flex, Link, Span } from '../../../style/styledElements/StyledElements';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../../asset/icons/userGuide/RightArrow.svg';
import styled from 'styled-components';
import { ReactComponent as Line } from '../../../asset/icons/userGuide/Line.svg';

function SidebarDropdown({ label, path, child, icon }) {

    const HoverDiv = styled(Div)`
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s ease-in;
    &:hover {
        transform: scale(1.01) translate(5px, 0px) skewX(0deg);
    }
`;
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const handleClick = ({ path }) => {
        setOpen(!open)
        navigate(path);
    }

    return (
        <Flex py='3' >
            <Flex
                width='100%'
                // onClick={() => setOpen(!open)}
                onClick={() => handleClick({ path })}
                cursor='pointer'
            >
                <Span
                    style={{
                        transform: open ? 'rotate(90deg)' : '',
                        transition: 'transform 380ms ease',
                    }}
                >
                    <Arrow />
                </Span>
                <Span px='1'>{icon}</Span>
                <HoverDiv>{label}</HoverDiv>
            </Flex>
            {open && (
                <Flex px='3'>
                    {child.map((child, index) => (
                        // <Link
                        //     py='1'
                        //     width='100%'
                        //     display='flex'
                        //     color={pathname === path ? 'blues.primary' : 'lightGrey'}
                        //     to={path}
                        //     key={index}
                        //     borderLeft='1px solid lightGrey'
                        // >
                        <Div py='1' borderLeft='1px solid lightGrey' >
                            <Flex alignItems='center'>
                                <Line />
                                <HoverDiv px='1'>{child}</HoverDiv>
                            </Flex>
                        </Div>
                        // </Link>
                    ))}
                </Flex>
            )}
        </Flex>

    )
}

export default SidebarDropdown;
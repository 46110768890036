import styled from 'styled-components';
import { Div } from '../../style/styledElements/StyledElements';

const Topper = styled(Div)`
    border-radius: 50%;
    display: inline-block;
    border: 4px solid ${(props) => props.variant};
    background-image: url(${(props) => props.pic});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    ::after {
        content: '${(props) => props.rank}';
        position: absolute;
        display: ${props => props.rank ? 'flex' : 'none'};
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        bottom: -10px;
        left: calc(50% - 12px);
        z-index: 2;
        color: white;
        background-color: ${(props) => props.variant};
        border-radius: 50%;
    }
`;

export default Topper;

const fontSizes = [10, 12, 14, 16, 18, 20, 24, 36, 48, 80, 96];
const lineHeights = [1, 1.25, 1.5];
const radii = ['0px', '2px', '4px', '8px', '10px', '16px', '24px', '36px', '48px'];
const space = [0, 4, 8, 10, 16, 24, 32, 48, 64, 128, 256, 512];

const breakpoints = ['576px', '768px', '992px', '1200px', '1400px'];

const theme = {
    breakpoints: {},

    space,
    fontSizes,
    lineHeights,

    colors: {
        black: '#161925',
        gray: '#a4a4a4',
        lightGrey: '#667085',
        deepGray: '#1C1C1C0D',
        whiteGray: '#F6F6F6',
        green: '#22C55E',
        darkGreen: '#027A48',
        lightGreen: '#E6F8EF',
        cream: '#F7CB87',
        navy: {
            primary: '#4E61B6',
            light: '#DCDFF0',
        },
        teal: {
            primary: '#47B6D4',
            light: '#DAF0F6',
        },
        blues: {
            primary: '#577CFF',
            900: '#80a7ff',
            800: '#8C97CC',
            100: '#F9FCFF',
        },
        gold: {
            primary: '#EBA90D',
            light: '#FEF0D0',
        },
    },

    radii,
};

theme.breakpoints.sm = breakpoints[0];
theme.breakpoints.md = breakpoints[1];
theme.breakpoints.lg = breakpoints[2];
theme.breakpoints.xl = breakpoints[3];

export default theme;

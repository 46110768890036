import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loader from '../../components/Loader';
import UserLogin from './sections/FirstTimeLogin';
import HelpLayout from './HelpLayout';
import HelpHome from './Index';
import ForgotPassword from './sections/ForgotPassword';
import StudyMaterials from './sections/StudyMaterials';
import EnrollCourse from './sections/EnrollCourse';
import Program from './sections/Programs';
import LiveClass from './sections/LiveClass';
import GetCertificate from './sections/GetCertificate';
import Notifications from './sections/Notifications';
import LeaderBoard from './sections/LeaderBoard';
import RateReview from './sections/RateReview';
import Logout from './sections/Logout';

function HelpRoute() {
    return (
        <>
            <Suspense fallback={<Loader />}>
                <Routes>
                    <Route path='/' element={<HelpLayout />} >
                        <Route index element={<HelpHome />} />
                        <Route path='login' element={<UserLogin />} />
                        <Route path='forgotpassword' element={<ForgotPassword />} />
                        <Route path='studyMaterials' element={<StudyMaterials />} />
                        <Route path='course' element={<EnrollCourse />} />
                        <Route path='program' element={<Program />} />
                        <Route path='liveClass' element={<LiveClass />} />
                        <Route path='get-certificate' element={<GetCertificate />} />
                        <Route path='notification' element={<Notifications />} />
                        <Route path='leader-board' element={<LeaderBoard />} />
                        <Route path='rating-review' element={<RateReview />} />
                        <Route path='logout' element={<Logout />} />
                    </Route>
                </Routes>
            </Suspense>
        </>
    );
}

export default HelpRoute;

import React, { useState } from "react";
import Heading from "../components/Heading";
import ImageComponent from "../components/ImageComponent";
import ScrollDiv from '../components/Scroll';
import { Div } from "../../../style/styledElements/StyledElements";

const EnrollCourse = ({ direction = 'column' }) => {
    const [column, setColumn] = useState(direction === 'column');

    const steps = [
        {
            id: 24,
            para: 'By Clicking on "Course" from the Sidebar, Participant is Navigate to Course list page.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-06/d95af05b-878f-410a-a3d3-afdc03a4f90a/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0"
        },
        {
            id: 25,
            para: 'Here Participant can See the List of Courses.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-25/49137d11-261f-48c2-8e05-fd721f4b046b/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0"
        },
        {
            id: 26,
            para: 'Participant can select a Course to Enroll and See the Course Details.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-25/c24d430f-934d-4bee-b37c-330008373b52/File.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=218,280"
        },
        {
            id: 27,
            para: 'Here Participant is Navigate to Course Detail Page and can See the Course Details. By Clicking on "Enroll Now" Participant can Enroll the Course.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-11/8297fbce-2015-421d-9a57-2d1fc2e25a3c/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=687,196"
        }
    ];

    return (
        <Div bg={column && '#F3F2F2'} width='95%' borderRadius='32px' pb='6'>

            <Div display={direction !== 'row' && 'none'} >
                <Heading text='Enroll To Courses' numberOfScreens={steps.length} />
            </Div>
            <ScrollDiv>
                <div className="scroll" style={{ display: 'flex', flexDirection: direction, overflowX: 'auto', alignItems: 'center' }}>
                    <Div py='4' width='60%' display={direction === 'row' && 'none'}>
                        <Heading mb='0' text='Enroll To Courses' numberOfScreens={steps.length} />
                    </Div>
                    {steps.map(step => (
                        <Div py='4' key={step.id} style={{ marginRight: '20px', width: '60%' }}>
                            <ImageComponent imageUrl={step.imageUrl} direction={direction} desc={step.para} />
                        </Div>
                    ))}
                </div>
            </ScrollDiv>

        </Div>
    );
};

export default EnrollCourse;

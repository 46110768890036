import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EnrolledCard from '../../components/EnrolledCard';
import Loader from '../../components/Loader';
import endpoints from '../../endpoints';
import { useAxiosGet } from '../../hooks/axiosHooks';
import { Div, Flex, Span } from '../../style/styledElements/StyledElements';
import Coursecard from '../../components/Coursecard';
import MandatoryCard from "../../components/MandatoryCard";

const courseItemBg = [
    '#708CDC',
    '#B7F8D7',
    '#D1D3FE',
    '#F7D763',
    '#E2DEC2',
    '#FCEFB4',
];

function AllEnrolledCourses() {
    const navigate = useNavigate();

    const [filterBy, setFilterBy] = useState(null);

    const { response, loading } = useAxiosGet(endpoints.course.enrolled);
    if (loading) {
        return <Loader />;
    }

    const filteredCourses = response.filter((course) => {
        if (filterBy === null) return true;
        if (filterBy === 2) return course.participant_mandatory_course === "1";
        return course.Completion_Status === filterBy;
    });

    const courseList = filteredCourses;

    return (
        <Div>
            <Div>My Courses</Div>

            <Div my='5' borderRadius='3' py='2' bg='white' width='max-content'>
                <Span
                    cursor='pointer'
                    py='2'
                    px='3'
                    borderRadius='3'
                    border={filterBy === null ? '1px solid' : 'none'}
                    onClick={() => setFilterBy(null)}
                >
                    All
                </Span>
                <Span
                    cursor='pointer'
                    py='2'
                    px='3'
                    borderRadius='3'
                    border={filterBy === 0 ? '1px solid' : 'none'}
                    onClick={() => setFilterBy(0)}
                >
                    Ongoing Courses
                </Span>
                <Span
                    cursor='pointer'
                    py='2'
                    px='3'
                    borderRadius='3'
                    border={filterBy === 1 ? '1px solid' : 'none'}
                    onClick={() => setFilterBy(1)}
                >
                    Completed Courses
                </Span>
                <Span
                    cursor='pointer'
                    py='2'
                    px='3'
                    borderRadius='3'
                    border={filterBy === 2 ? '1px solid' : 'none'}
                    onClick={() => setFilterBy(2)}
                >
                    Mandatory Courses
                </Span>
            </Div>

            <Flex mb='5'>
                {courseList.map((course,index) => {
                    let tasksCompleted = 0;
                    course?.Course_items.forEach((item) => {
                        if (Number(item.Course_Item_Completion_Status) === 2) {
                            tasksCompleted++;
                        }
                    });

                    const formatDateTime = (dateString) => {
                        const date = new Date(dateString);
                        const options = {
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                        };
                        return date.toLocaleString(
                            "en-US",
                            options
                        );
                    };

                    const startDate = formatDateTime(
                        course.START_DATE
                    );
                    const endDate = formatDateTime(course.END_DATE);

                    const cardProps = {
                        title: course.Course_NAME,
                        completionStatus: `${tasksCompleted} / ${course.Course_items?.length} Tasks completed`,
                        mb: "3",
                        credits: course.Course_Credits,
                        progress: Math.floor(
                            (tasksCompleted /
                                course.Course_items?.length) *
                                100
                        ),
                        location: course.Location_name,
                        expiry: `${startDate} to ${endDate}`,
                        onClick: () => {
                            navigate("/course-overview-enrolled", {
                                state: {
                                    id: course.Course_ID,
                                },
                            });
                        },
                        height: "100%",
                    };
                    return (
                        <Div
                            width={{ lg: 1 / 4, md: 1 / 3, sm: 1 / 2 }}
                            pr='3'
                            key={course.Course_ID}
                            mb='3'
                        >
                            <Coursecard
                                dp={course.COURSE_DP_URL}
                                bg={courseItemBg[index % 6]}
                            >
                                 {course.participant_mandatory_course ===
                                            "1" ? (
                                                <MandatoryCard {...cardProps} />
                                            ) : (
                                                <EnrolledCard {...cardProps} />
                                            )}
                            </Coursecard>
                        </Div>
                    );
                })}
            </Flex>
        </Div>
    );
}

export default AllEnrolledCourses;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as HatIcon } from '../../asset/icons/userGuide/BlackHat.svg';
import { ReactComponent as LiveClassIcon } from '../../asset/icons/userGuide/LiveClassSymbol.svg';
import { ReactComponent as ProgramIcon } from '../../asset/icons/userGuide/square_foot.svg';
import { ReactComponent as NotesIcon } from '../../asset/icons/userGuide/lab_profile.svg';
import { ReactComponent as KeyIcon } from '../../asset/icons/userGuide/Vertical_Key.svg';
import { ReactComponent as PrivacyIcon } from '../../asset/icons/userGuide/encrypted.svg';
import { Div, Flex, Span } from '../../style/styledElements/StyledElements';

const commonTopics = [
    {
        path: 'course',
        name: 'Course',
        icon: <HatIcon />,
        iconBodyColor: '#FFBE0B4A',
        text: 'Enroll into a Course'
    },
    {
        path: 'liveClass',
        name: 'Schedule Live Class',
        icon: <LiveClassIcon />,
        iconBodyColor: '#5F9DFFC7',
        text: 'How to join a Live Class'
    },
    {
        path: 'program',
        name: 'Program',
        icon: <ProgramIcon />,
        iconBodyColor: '#667085',
        text: 'Enroll into a Program'
    },
    {
        path: 'get-certificate',
        name: 'Get Certificate',
        icon: <NotesIcon />,
        iconBodyColor: '#B692F6',
        text: 'Get Certificate'
    },
    {
        path: 'forgotPassword',
        name: 'Forgot Password',
        icon: <KeyIcon />,
        iconBodyColor: '#93FF90C7',
        text: 'Forgot Password'
    },

];

const otherTopics = [
    {
        topic: 'What if you miss the Live Class ?',
        path: 'liveClass'
    },
    {
        topic: 'How to Determine Your Ranking Among Peers?',
        path: 'leader-board'
    },
    {
        topic: 'First Time User Login',
        path: 'login'
    },
]






const Index = () => {
    const navigate = useNavigate();
    const handleOnclick = ({ path }) => {
        navigate(path);
    }

    const Card = ({ icon, text, iconBodyColor, path }) => (
        <Flex
            borderRadius='15px'
            p='3'
            my='2'
            mb='4'
            bg='white'
            alignItems='center'
            justifyContent='center'
            width='167px'
            height='205px'
            cursor='pointer'
            onClick={() => handleOnclick({ path: path })}
        >
            <Flex
                mt='2'
                p='3'
                borderRadius='15px'
                height='50%'
                width='70%'
                bg={iconBodyColor}
                alignItems='center'
                justifyContent='center'
            >
                {icon}
            </Flex>
            <Div
                color='#212529'
                fontSize='19px'
                fontWeight='600'
                textAlign='center'
                width='90%'
            >
                {text}
            </Div>
        </Flex>
    );

    return (
        <>
            <Div bg='#F3F2F2' borderRadius='32px' width='95%' >
                <Flex alignItems='center' justifyContent='center' >
                    <Flex width='50%' overflow='auto'>
                        <Div px='2' width='100%' pt='7'>
                            <Span fontSize='19px' fontWeight='600' >Common Topics</Span>
                        </Div>
                        {commonTopics.map((topics, index) => (
                            <Flex
                                key={index}
                                width='30%'
                                height='100%'
                                alignItems='center'
                                justifyContent='center'

                            >
                                <Card icon={topics.icon} text={topics.text} iconBodyColor={topics.iconBodyColor} path={topics.path} />
                            </Flex>
                        ))}
                        <Div px='2' width='100%' pt='5'>
                            <Span fontSize='19px' fontWeight='600' >Other Flows</Span>
                        </Div>
                        <Div mb='7'>
                            {
                                otherTopics.map((topics, index) => (
                                    <Div
                                        px='2'
                                        py='2'
                                        key={index}
                                        color='#005FDB'
                                        fontSize='16px'
                                        fontWeight='500'
                                        onClick={() => handleOnclick({ path: topics.path })}
                                    >
                                        <Span cursor='pointer'>{topics?.topic}</Span>
                                    </Div>
                                ))
                            }
                        </Div>
                    </Flex>
                </Flex>
            </Div >
        </>
    );
};



export default Index;

import styledComponents from 'styled-components';

const Spinner = styledComponents.span`
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50px;
    width: 50px;
    margin: -25px 0 0 -25px;
    border: 2px solid transparent;
    border-top-color: #000000;
    box-shadow: 0 0 5px skyblue;
    border-radius: 50%;
    animation: spin1 1.5s ease infinite;

    &:before {
        content: '';
        position: absolute;
        top: 7px;
        right: 7px;
        bottom: 7px;
        left: 7px;
        border: 2px solid transparent;
        border-radius: 50%;
        border-top-color: #000000;
        box-shadow: 0 0 5px skyblue;
        -webkit-animation: spin2 1s linear infinite;
        animation: spin2 1s linear infinite;
    }

    &:after {
        content: '';
        position: absolute;
        top: 15px;
        right: 15px;
        bottom: 15px;
        left: 15px;
        border: 2px solid transparent;
        border-radius: 50%;
        border-top-color: #000000;
        box-shadow: 0 0 5px skyblue;
        -webkit-animation: spin1 1.5s linear infinite;
        animation: spin1 1.5s linear infinite;
    }

    @keyframes spin1 {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
            -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
        }
    }
    @keyframes spin2 {
        0% {
            -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
            -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
        }
        100% {
            -webkit-transform: rotateX(360deg);
            transform: rotateX(360deg);
            -webkit-transform: rotateX(360deg);
            transform: rotateX(360deg);
        }
    }
`;

export default Spinner;

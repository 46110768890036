import React, { useState } from "react";
import ImageComponent from "../components/ImageComponent";
import Heading from "../components/Heading";
import ScrollDiv from '../components/Scroll';
import { Div } from "../../../style/styledElements/StyledElements";

export const LiveClass = ({ direction = 'column' }) => {

    const [column, setColumn] = useState(direction === 'column');
    const steps = [
        {
            id: 62,
            para: 'Click "Schedule" from the sidebar to navigate to schedule page. View the Schedules of Live Classes.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-12/e018f105-933d-40f9-b096-2d525eb80cb8/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1891,872&force_format=png&width=1120.0'
        },
        {
            id: 63,
            para: 'Select a Date to View the Scheduled Live Class on the Particular Date.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-25/ac8669cc-df57-4496-9e0c-d093c74ea7f6/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1891,872&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=389,279'
        },
        {
            id: 64,
            para: 'Participant can Join the Live Class by Clicking the "Join" button.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-12/b969ffbf-e60f-43f1-8fec-67ee4754d3e5/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1904,881&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=998,105'
        },
        {
            id: 65,
            para: 'Participant can view the assigned Live classes on the dates indicated by the red dot.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-25/d04dd3a5-d323-4bb0-bb12-84d925229ee5/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1904,881&force_format=png&width=1120.0'
        },
        {
            id: 66,
            para: 'Participant is Navigated to the Live Class page.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-27/dceae035-060e-4528-8c4d-bb9fcd2fb01d/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=466,234'
        },
    ];

    return (
        <Div bg={column && '#F3F2F2'} width='95%' borderRadius='32px' pb='6'>
            <Div display={direction !== 'row' && 'none'} >
                <Heading text='Live Class' numberOfScreens={steps.length} />
            </Div>
            <ScrollDiv>
                <div className='scroll' style={{ display: 'flex', flexDirection: direction, overflowX: 'auto', alignItems: 'center' }}>
                    <Div py='4' width='60%' display={direction === 'row' && 'none'}>
                        <Heading mb='0' text='Live Class' numberOfScreens={steps.length} />
                    </Div>
                    {steps.map(step => (
                        <Div py='4' key={step.id} style={{ marginRight: '20px', width: '60%' }}>
                            <ImageComponent imageUrl={step.imageUrl} direction={direction} desc={step.para} />
                        </Div>
                    ))}
                </div>
            </ScrollDiv>
        </Div>
    );
};

export default LiveClass;

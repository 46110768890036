import React from 'react';
import { Div, Flex, Span } from '../style/styledElements/StyledElements';
import { ReactComponent as Tick } from '../asset/icons/Tick.svg';
import { ReactComponent as Education } from '../asset/icons/Education.svg';
import Circle from 'react-circle';

function EnrolledCard(props) {
    const { title, completionStatus, credits, progress, ...rest } = props;

    return (
        <Flex
            boxShadow='0px 3px 8px rgba(74, 85, 104, 0.07)'
            borderRadius='20px'
            p='3'
            fontSize='1'
            bg='white'
            cursor='pointer'
            justifyContent='space-between'
            {...rest}
        >
            <Div width='75%'>
                <Div
                    fontWeight='500'
                    mb='2'
                    style={{
                        textWrap: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        lineHeight: '1'
                    }}
                >
                    {title}
                </Div>

                <Flex color='rgba(42, 172, 142, 0.7)'>
                    <Tick />
                    <Div ml='2' pt='2px'>
                        {completionStatus}
                    </Div>
                </Flex>

                <Flex mt='2' fontSize='1' alignItems='center'>
                    <Education />
                    <Span ml='2'>{credits} Credits</Span>
                </Flex>
            </Div>
            <Span width='25%'>
                <Circle
                    progress={progress}
                    // size={70}
                    progressColor='#FF9878'
                    bgColor='#E3E4E8'
                    roundedStroke
                    textColor='#050D2E'
                    lineWidth={30}
                    textStyle={{
                        font: '500 5rem Inter',
                    }}
                    responsive
                />
            </Span>
        </Flex>
    );
}

export default EnrolledCard;

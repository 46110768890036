import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Spinner from '../style/styledElements/Spinner';

function Loader() {
    const [el] = useState(document.createElement('div'));

    useEffect(() => {
        document.body.appendChild(el);
        return () => {
            document.body.removeChild(el);
        };
    }, [el]);

    return <>{createPortal(<Spinner />, el)}</>;
}

export default Loader;

import React from 'react';
import { Div, Flex, Span } from '../../../style/styledElements/StyledElements';
import { ReactComponent as GreenDot } from '../../../asset/icons/userGuide/Dot.svg'

function Heading({ text, numberOfScreens, ...rest }) {
    return (
        <Flex
            alignItems='center'
            mb='4'
            mt='5'
            {...rest}
        >
            <Span
                fontWeight='500'
                fontSize='20px'
                borderBottom='2px solid black'
            >{text}</Span>
            <Div
                borderRadius='16px'
                ml='2'
                bg='lightGreen'
                py='1'
                px='2'
                color='darkGreen'>
                <GreenDot />
                <Span pl='1'>{numberOfScreens} Screen{numberOfScreens > 1 && 's'}</Span>
            </Div>
        </Flex >
    )
};

export default Heading;

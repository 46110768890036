import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Main from '../layout/Main';
import AllEnrolledCourses from '../pages/allCoursesList/AllEnrolledCourses';
import AllEnrolledPrograms from '../pages/allProgramsList/AllEnrolledPrograms';
import Dashboard from '../pages/dashboard/Index';
import { useAuthenticationState } from '../context/AuthContext';
import LeaderBoard from '../pages/leaderBoard/Index';
import HelpRoute from '../pages/helpFacility/HelpRoute';
import { SearchProvider } from '../context/SearchContext';

const Course = lazy(() => import('../pages/courseList/Index'));
const UnenrolledCourse = lazy(() => import('../pages/unenrolledCourse/Index'));
const EnrolledCourse = lazy(() => import('../pages/enrolledCourse/Index'));
const Login = lazy(() => import('../pages/login/Login'));
const Programs = lazy(() => import('../pages/programList/Index'));
const UnEnrolledProgram = lazy(() =>
    import('../pages/unenrolledProgram/Index')
);
const EnrolledProgramOverview = lazy(() =>
    import('../pages/enrolledProgram/Index')
);
const Profile = lazy(() => import('../pages/profile/Index'));
const ScheduleCalendar = lazy(() => import('../pages/scheduleCalendar/Index'));

function GenerateRoutes() {
    const { user } = useAuthenticationState();

    if (!user.username) {
        return (
            <Routes>
                <Route path='*' element={<Login />} />
                {/* <Route path='*' element={<Navigate to={'/login'} />} /> */}
            </Routes>
        );
    }

    return (
        <SearchProvider>
            <Routes>
                <Route path='/' element={<Main />}>
                    <Route index element={<Dashboard />} />
                    <Route path='course' element={<Course />} />
                    <Route
                        path='schedule-calendar'
                        element={<ScheduleCalendar />}
                    />
                    <Route
                        path='course-overview-unregistered'
                        element={<UnenrolledCourse />}
                    />
                    <Route
                        path='course-overview-enrolled'
                        element={<EnrolledCourse />}
                    />
                    <Route
                        path='all-enrolled-course'
                        element={<AllEnrolledCourses />}
                    />
                    <Route
                        path='all-enrolled-programs'
                        element={<AllEnrolledPrograms />}
                    />
                    <Route path='programs' element={<Programs />} />
                    <Route
                        path='program-overview-unregistered'
                        element={<UnEnrolledProgram />}
                    />
                    <Route
                        path='program-overview-enrolled'
                        element={<EnrolledProgramOverview />}
                    />
                    <Route path='leader-board' element={<LeaderBoard />} />
                    <Route path='profile' element={<Profile />} />
                </Route>
                <Route path='help/*' element={<HelpRoute />} />
                <Route path='/login' element={<Login />} />
            </Routes>
        </SearchProvider>
    );
}

export default GenerateRoutes;

import React, { useState } from 'react';
import Heading from '../components/Heading';
import ImageComponent from '../components/ImageComponent';
import { Div } from '../../../style/styledElements/StyledElements';
import ScrollDiv from '../components/Scroll';

const Login = ({ direction = 'column' }) => {

    const [column, setColumn] = useState(direction === 'column');
    const steps = [
        {
            id: 1,
            para: 'Click on "Logging in for first time ?" to Sign up to StreamHall.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-06/ace55d51-3b73-4a06-934c-1d85732af787/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0"
        },
        {
            id: 2,
            para: "Enter the Registered Email",
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-06/edd92369-97d1-4364-abb4-799989fe0e99/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=421,77"
        },
        {
            id: 3,
            para: 'Click on "Submit" to Activate the User.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-11/fd1b68c3-6e31-4ab2-8807-004bc59f3884/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=482,148"
        },
        {
            id: 4,
            para: 'Copy the Username and Password that are sent to the Registered Mail for Login.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-11/50a77d55-03d9-4f65-a7ec-e18baf2319e6/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=286,321"
        },
        {
            id: 5,
            para: 'Enter the Username and Password.',
            imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-12/14fd8460-bd94-4d97-92c0-726a8e1f058d/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1637,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=233,245"
        },

    ];

    return (
        <Div bg={column && '#F3F2F2'} width='95%' borderRadius='32px' pb='6'>
            <Div display={direction !== 'row' && 'none'} >
                <Heading text='First Time Login' numberOfScreens={steps.length} />
            </Div>

            <ScrollDiv >
                <div className='scroll' style={{ display: 'flex', flexDirection: direction, overflow: 'auto', alignItems: 'center' }}>
                    <Div pt='4' width='64%' display={direction === 'row' && 'none'}>
                        <Heading mb='0' text='First Time Login' numberOfScreens={steps.length} />
                    </Div>
                    {steps.map(step => (
                        <Div py='4' key={step.id} style={{ marginRight: '20px', width: '63%' }}>
                            <ImageComponent imageUrl={step.imageUrl} direction={direction} desc={step.para} />
                        </Div>
                    ))}
                </div>
            </ScrollDiv>
        </Div>
    );
};

export default Login;

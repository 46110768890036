import React, { useState } from "react";
import ImageComponent from "../components/ImageComponent";
import Heading from "../components/Heading";
import ScrollDiv from '../components/Scroll';
import { Div } from "../../../style/styledElements/StyledElements";

export const GetCertificate = ({ direction = 'column' }) => {

    const [column, setColumn] = useState(direction === 'column');
    const steps = [
        {
            id: 47,
            para: 'Once participants have successfully completed the course content, a button "Get Certificate" is enabled. By Clicking on "Get Certificate" Participant can View and Download the Certificate.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-25/62254a8b-64ef-4fba-a45e-4b30523cf5ae/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1885,880&force_format=png&width=1120.0'
        },
        {
            id: 48,
            para: 'By clicking on the "Get certificate" button participant can see the course certificate.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-25/5b7b34b5-9956-4712-a7c4-d88b03db2eda/screenshot.png?tl_px=0,0&br_px=1911,871&force_format=png&width=1120.0'
        }
    ];

    return (
        <Div bg={column && '#F3F2F2'} width='95%' borderRadius='32px' pb='6'>
            <Div display={direction !== 'row' && 'none'} >
                <Heading text='Get Certificate' numberOfScreens={steps.length} />
            </Div>
            <ScrollDiv>
                <div className='scroll' style={{ display: 'flex', flexDirection: direction, overflowX: 'auto', alignItems: 'center' }}>
                    <Div py='4' width='60%' display={direction === 'row' && 'none'}>
                        <Heading mb='0' text='Get Certificate' numberOfScreens={steps.length} />
                    </Div>
                    {steps.map(step => (
                        <Div py='4' key={step.id} style={{ marginRight: '20px', width: '60%' }}>
                            <ImageComponent imageUrl={step.imageUrl} direction={direction} desc={step.para} />
                        </Div>
                    ))}
                </div>
            </ScrollDiv>
        </Div>
    );
};

export default GetCertificate;

import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { getErrorMsg } from '../../helpers/getMsgFromErrorObject';

function useSearchData({ urls, dependency }) {
    const [data, setData] = useState({ enrolled: [], unenrolled: [] });
    const [reloadToken, setReloadToken] = useState(true);
    const [loading, setLoading] = useState(true);
    const reload = () => setReloadToken(!reloadToken);

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        const getData = async (url, key) => {
            try {
                const res = await axios.get(url, {
                    signal: controller.signal,
                });
                console.log(res, 'response data');
                setData((prev) => ({
                    ...prev,
                    [key]: res?.data.responseData,
                }));
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };

        const fetchSequentially = async () => {
            for (const key in urls) {
                if (Object.hasOwnProperty.call(urls, key)) {
                    await getData(urls[key], key);
                }
            }
        };

        fetchSequentially();

        return () => {
            controller.abort();
            setLoading(false);
        };
    }, [urls, reloadToken]);

    return { data, reload, loading };
}

export default useSearchData;

import {
    Div,
    Flex,
    Input,
    Span,
} from '../../style/styledElements/StyledElements';
import { ReactComponent as Lens } from '../../asset/icons/Lens.svg';
import { ReactComponent as CarrotDown } from '../../asset/icons/CarrotDown.svg';
import styled from 'styled-components';
import Menu from './Menu';
import { useEffect, useRef } from 'react';
import { useState } from 'react';

const StyledInput = styled(Input)`
    border: none;
    &::placeholder {
        color: #00000040;
    }
`;

function SearchInput({
    onChange,
    onSelect,
    data,
    type,
    toggleType,
    value,
    onClear,
}) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const searchRef = useRef(null);
    const menuRef = useRef(null);

    useEffect(() => {
        const handler = (event) => {
            if (
                isMenuOpen &&
                searchRef.current &&
                !searchRef.current.contains(event.target) &&
                menuRef.current &&
                !menuRef.current.contains(event.target)
            ) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, [isMenuOpen]);

    return (
        <Flex
            flexWrap='nowrap'
            border='1px solid #00000050'
            borderRadius='4'
            alignItems='center'
            position='relative'
            width='434px'
            justifyContent='center'
            ref={searchRef}
        >
            <Div pl='4' mt='2px'>
                <Lens />
            </Div>
            <StyledInput
                placeholder={'Search ' + type}
                pl='4'
                onChange={(e) => {
                    onChange(e);

                    if (!isMenuOpen) {
                        setIsMenuOpen(true);
                    }
                    if (e.target.value === '') {
                        setIsMenuOpen(false);
                    }
                }}
                value={value}
                className='search'
            />
            {value && (
                <Span px='3' cursor='pointer' lineHeight = '1' onClick={onClear}>
                    x
                </Span>
            )}
            <Flex
                fontSize='1'
                py='1'
                fontWeight='500'
                borderLeft='1px solid #00000080'
                flexWrap='nowrap'
                alignItems='center'
                cursor='pointer'
                onClick={toggleType}
            >
                <Span ml='5' minWidth='60px'>
                    {type}
                </Span>
                <Span ml='5' mr='4'>
                    <CarrotDown />
                </Span>
            </Flex>

            <Menu
                show={isMenuOpen}
                items={data}
                onSelect={onSelect}
                getLabel={(item) =>
                    type === 'Courses'
                        ? item.Course_NAME
                        : item.Course_Program_NAME
                }
                ref={menuRef}
            />
        </Flex>
    );
}

export default SearchInput;

import React from 'react';
import styled from 'styled-components';
import { Div } from '../../style/styledElements/StyledElements';

const Item = styled(Div)`
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
    color: #2e2e2e;
    &:hover {
        background-color: lightgray;
    }
`;

const Menu = React.forwardRef((props, ref) => {
    const {
        show = false,
        items,
        onSelect,
        getLabel = () => {},
        ...rest
    } = props;
    
    return (
        <Div
            bg='#d9e3ff'
            width='100%'
            position='absolute'
            top='115%'
            borderRadius='4'
            display={show ? 'block' : 'none'}
            maxHeight='400px'
            overflow='auto'
            fontSize='1'
            p='2'
            ref={ref}
            {...rest}
        >
            {items.length > 0 ? (
                items.map((item, i) => (
                    <Item onClick={() => onSelect(item)} key={i}>
                        {getLabel(item)}
                    </Item>
                ))
            ) : (
                <Item textAlign='center'>No results found</Item>
            )}
        </Div>
    );
});

export default Menu;

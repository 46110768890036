import React from 'react';
import Loader from '../../components/Loader';
import { useAuthenticationState } from '../../context/AuthContext';
import { useAxiosGet } from '../../hooks/axiosHooks';
import {
    Col,
    Div,
    Flex,
    Grid,
} from '../../style/styledElements/StyledElements';
import Enrolled from './Enrolled';
import LiveClass from './LiveClass';
import OngoingCourses from './OngoingCourses';
import SummaryCard from './SummaryCard';
import { ReactComponent as Graduate } from '../../asset/icons/dashboard/GraduateBlue.svg';
import { ReactComponent as Notepad } from '../../asset/icons/dashboard/NotePadNavy.svg';
import { ReactComponent as Badge } from '../../asset/icons/dashboard/Badge.svg';
import endpoints from '../../endpoints';

function Dashboard() {
    const { user } = useAuthenticationState();

    const { response, loading } = useAxiosGet(
        endpoints.dashboard.get + user.id
    );

    const {
        todayliveclass,
        courseArray,
        completedcourse_count,
        completed_programs_count,
        notCompletedcourse_count,
        notcompleted_programs_count,
        Badge_count,
    } = response;

    if (loading) {
        return <Loader />;
    }
    return (
        <>
            <Div mb='6'>
                <Div fontWeight='600' fontSize='5' textTransform='capitalize'>
                    Welcome Back {user.name}
                </Div>
                <Div color='gray'>Do your best today</Div>
            </Div>

            <Grid gridTemplateColumns='1fr 1fr 1fr' gridGap='8'>
                <Div>
                    <Enrolled
                        myCourseCount={
                            response.totalentroledcourse_count || '0'
                        }
                        myProgramCount={
                            response.totalentroledprogram_count || '0'
                        }
                    />
                    <LiveClass classes={todayliveclass} />
                </Div>

                <Flex flexDirection='column'>
                    Summary
                    <SummaryCard
                        Icon={Graduate}
                        name='Course'
                        status='Completed'
                        color='#DAF0F6'
                        completedCount={
                            completedcourse_count?.Enrolledcourse_completed_count ||
                            0
                        }
                        onProgressCount={
                            notCompletedcourse_count?.Enrolledcourses_progress_count ||
                            0
                        }
                    />
                    <SummaryCard
                        Icon={Notepad}
                        name='Programs'
                        status='Completed'
                        color='#DCDFF0'
                        completedCount={
                            completed_programs_count?.completedprogram_count || 0
                        }
                        onProgressCount={
                            notcompleted_programs_count?.progress_program_count ||
                            0
                        }
                    />
                </Flex>
                <Div gap='4'>
                    <OngoingCourses courses={courseArray} />
                </Div>
            </Grid>
        </>
    );
}

export default Dashboard;

import React, { useState, Suspense } from 'react';
import { Div, Img, Flex } from '../../../style/styledElements/StyledElements';
import Loader from '../../../components/Loader';

const LazyImage = React.lazy(() => import('./LazyImageComponent'));

const ImageComponent = ({ imageUrl, direction, desc }) => {
    const [column, setColumn] = useState(direction === 'column');

    return (
        <Flex p={column ? '5' : 0} bg={column && 'deepGray'} borderRadius='10px' border='1px solid #0000000D'>
            <Suspense fallback={<Loader />}>
                <LazyImage
                    src={imageUrl}
                    style={{ borderRadius: '10px' }}
                    height={!column && '260px'}
                    width={column && '100%'}
                    name={desc}
                    alt={desc}
                />
            </Suspense>
            <Div display={!column && 'none'} pt='2' fontWeight='500' fontSize='16px'>{desc}</Div>
        </Flex>
    );
};

export default ImageComponent;

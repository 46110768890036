import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchInput from './SearchInput';
import { useSearch } from '../../context/SearchContext';

function Search() {
    const [searchValue, setSearchValue] = useState('');

    const { data, type, toggleType } = useSearch();
    const navigate = useNavigate();
    // console.log(data, 'data');
    const { enrolled, unenrolled } = data;

    const onSelect = (item) => {
        if (type === 'Courses') {
            const isSelectedItemEnrolled = enrolled.find(
                (course) => course.Course_ID === item.Course_ID
            );

            const route = isSelectedItemEnrolled
                ? '/course-overview-enrolled'
                : '/course-overview-unregistered';

            navigate(route, {
                state: {
                    id: item.Course_ID,
                },
            });
        }

        if (type === 'Programs') {
            const isSelectedItemEnrolled = enrolled.find(
                (course) => course.Course_Program_ID === item.Course_Program_ID
            );

            const route = isSelectedItemEnrolled
                ? '/program-overview-enrolled'
                : '/program-overview-unregistered';

            navigate(route, {
                state: {
                    id: item.Course_Program_ID,
                    categoryId: item.Course_Category_ID,
                },
            });
        }
    };

    const menuLabel =
        type === 'Courses' ? 'Course_NAME' : 'Course_Program_NAME';

    // remaining debounce
    const filteredData = [...enrolled, ...unenrolled].filter(
        (item) =>
            item[menuLabel]
                ?.replaceAll(' ', '')
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()?.replaceAll(' ', '')) > -1
    );

    const clearInput = () => {
        setSearchValue('');
    };

    return (
        <SearchInput
            data={filteredData}
            toggleType={toggleType}
            onSelect={onSelect}
            onChange={(e) => setSearchValue(e.target.value)}
            onClear={clearInput}
            type={type}
            value={searchValue}
        />
    );
}

export default Search;

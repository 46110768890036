import React, { Suspense, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Nav from '../components/navbar/Nav';
import Sidebar from '../components/Sidebar';
import Loader from '../components/Loader';
import { Div } from '../style/styledElements/StyledElements';
import theme from '../style/theme';
import { useAxiosGet } from '../hooks/axiosHooks';
import endpoints from '../endpoints';
import { SearchProvider } from '../context/SearchContext';
import toast from 'react-hot-toast';

function Main() {
    const location = useLocation();
    const mainContent = React.useRef(null);

    const {
        response: notifications,
        loading,
        reFetch,
    } = useAxiosGet(endpoints.notification.list);

    useEffect(() => {
        if (location.pathname === '/leader-board') {
            document.body.style.backgroundColor = '#fde8ea';
        } else {
            document.body.style.backgroundColor = theme.colors.blues[100];
        }
        return () => {
            document.body.style.backgroundColor = 'white';
        };
    }, [location]);

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    return (
        <>
            <Nav notifications={notifications} refreshNotification={reFetch} />
            <Sidebar />
            <Div ml='100px' mt='80px' p='6' ref={mainContent} color='black'>
                <Suspense fallback={<Loader />}>
                    {loading ? <Loader /> : <Outlet />}
                </Suspense>
            </Div>
        </>
    );
}

export default Main;

import React from 'react';
import { Div, Flex, Link, Span } from '../../../style/styledElements/StyledElements';
import { useNavigate } from 'react-router-dom';
import SidebarDropdown from './SidebarDropdown';
import { ReactComponent as Login } from '../../../asset/icons/userGuide/login.svg';
import { ReactComponent as Key } from '../../../asset/icons/userGuide/key_vertical.svg';
import { ReactComponent as Book } from '../../../asset/icons/userGuide/auto_stories.svg';
import { ReactComponent as Hat } from '../../../asset/icons/userGuide/school.svg';
import { ReactComponent as Program } from '../../../asset/icons/userGuide/Program.svg';
import { ReactComponent as LiveClass } from '../../../asset/icons/userGuide/Liveclass.svg';
import { ReactComponent as Alert } from '../../../asset/icons/userGuide/add_alert.svg';
import { ReactComponent as Badge } from '../../../asset/icons/userGuide/badge.svg';
import { ReactComponent as LeaderBoard } from '../../../asset/icons/userGuide/social_leaderboard.svg';
import { ReactComponent as Star } from '../../../asset/icons/userGuide/family_star.svg';
import { ReactComponent as Logout } from '../../../asset/icons/userGuide/logout.svg';

const links = [
    {
        path: 'login',
        name: 'login',
        child: ['Login', 'First Time Login'],
        icon: <Login />,
    },
    {
        path: 'forgotPassword',
        name: 'Forgot Password',
        child: ['Forgot Password'],
        icon: <Key />,
    },
    {
        path: 'studyMaterials',
        name: 'Study Materials',
        child: ['Course Content', 'Video Tutorial', 'Text Document', 'Attend Live Class', 'Quiz'],
        icon: <Book />,
    },
    {
        path: 'course',
        name: 'Course',
        child: ['Course'],
        icon: <Hat />,
    },
    {
        path: 'program',
        name: 'Program',
        child: ['Program'],
        icon: <Program />,
    },
    {
        path: 'liveClass',
        name: 'Schedule Live Class',
        child: ['Schedule Live Class'],
        icon: <LiveClass />,
    },
    {
        path: 'get-certificate',
        name: 'Get Certificate',
        child: ['Get Certificate'],
        icon: <Badge />,
    },
    {
        path: 'notification',
        name: 'Notifications',
        child: ['Notifications'],
        icon: <Alert />,
    },
    {
        path: 'leader-board',
        name: 'Leader Board',
        child: ['Leader Board'],
        icon: <LeaderBoard />,
    },
    {
        path: 'rating-review',
        name: 'Rating & Review',
        child: ['Rating & Review'],
        icon: <Star />,
    },
    {
        path: 'logout',
        name: 'Log out',
        child: ['Log out'],
        icon: <Logout />,
    }

];
function Sidebar() {
    const navigate = useNavigate();
    return (
        <Div
            height='100vh'
            width='245px'
            boxShadow='0px 4px 4px rgba(0, 0, 0, 0.3)'
            fontSize={1}
            overflow='auto'
            zIndex='900'
            bg='whiteGray'
            position='fixed'
            top='0'
            left='0'
            pt='10%'
        >
            <Div
                mx='4'
                borderRadius='12px'
                bg='deepGray'
                px='3'
                py='3'
                mb='2'
                fontSize='14px'
                fontWeight='400'
            >
                Overview
            </Div>
            <Div textAlign='center' mx='5'>
                {links.map((link) => (
                    <SidebarDropdown label={link.name} path={link.path} child={link.child} icon={link.icon} />
                ))}
            </Div>
        </Div>
    );
}

export default Sidebar;

import React, { useState } from "react";
import ImageComponent from "../components/ImageComponent";
import Heading from "../components/Heading";
import ScrollDiv from '../components/Scroll';
import { Div } from "../../../style/styledElements/StyledElements";

export const Logout = ({ direction = 'column' }) => {

    const [column, setColumn] = useState(direction === 'column');
    const steps = [
        {
            id: 71,
            para: 'Click on "Log out" icon to Log out from the Application.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-23/6253d636-8507-4fde-8721-1d906945bb13/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1910,880&force_format=png&width=1120.0'
        },
    ];

    return (
        <Div bg={column && '#F3F2F2'} width='95%' borderRadius='32px' pb='6'>
            <Div display={direction !== 'row' && 'none'} >
                <Heading text='Logout' numberOfScreens={steps.length} />
            </Div>
            <ScrollDiv>
                <div className='scroll' style={{ display: 'flex', flexDirection: direction, overflowX: 'auto', alignItems: 'center' }}>
                    <Div py='4' width='60%' display={direction === 'row' && 'none'}>
                        <Heading mb='0' text='Logout' numberOfScreens={steps.length} />
                    </Div>
                    {steps.map(step => (
                        <Div py='4' key={step.id} style={{ marginRight: '20px', width: '60%' }}>
                            <ImageComponent imageUrl={step.imageUrl} direction={direction} desc={step.para} />
                        </Div>
                    ))}
                </div>
            </ScrollDiv>
        </Div>
    );
};

export default Logout;

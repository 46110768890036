import React, { useState } from "react";
import ImageComponent from "../components/ImageComponent";
import Heading from "../components/Heading";
import ScrollDiv from '../components/Scroll';
import { Div } from "../../../style/styledElements/StyledElements";

export const Notifications = ({ direction = 'column' }) => {

  const [column, setColumn] = useState(direction === 'column');
  const steps = [
    {
      id: 67,
      para: 'Click on the Notification Bell Icon to See the Notifications.',
      imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-23/555ad93d-18c3-4d8f-be6b-40100c4aa1b3/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1909,884&force_format=png&width=1120.0'
    },
    {
      id: 68,
      para: 'The Notifications both Unread and Read can be Viewed in the Notification bar.',
      imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-23/a2732cbb-84da-4429-a919-8d702128b9e9/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1901,884&force_format=png&width=1120.0'
    },
  ];

  return (
    <Div bg={column && '#F3F2F2'} width='95%' borderRadius='32px' pb='6'>
      <Div display={direction !== 'row' && 'none'} >
        <Heading text='Notifications' numberOfScreens={steps.length} />
      </Div>
      <ScrollDiv>
        <div className='scroll' style={{ display: 'flex', flexDirection: direction, overflowX: 'auto', alignItems: 'center' }}>
          <Div py='4' width='60%' display={direction === 'row' && 'none'}>
            <Heading mb='0' text='Notifications' numberOfScreens={steps.length} />
          </Div>
          {steps.map(step => (
            <Div py='4' key={step.id} style={{ marginRight: '20px', width: '60%' }}>
              <ImageComponent imageUrl={step.imageUrl} direction={direction} desc={step.para} />
            </Div>
          ))}
        </div>
      </ScrollDiv>
    </Div>
  );
};

export default Notifications;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EnrolledCard from '../../components/EnrolledCard';
import Loader from '../../components/Loader';
import endpoints from '../../endpoints';
import { useAxiosGet } from '../../hooks/axiosHooks';
import { Div, Flex, Span } from '../../style/styledElements/StyledElements';

function AllEnrolledPrograms() {
    const navigate = useNavigate();

    const [filterBy, setFilterBy] = useState(null);

    const { response, loading } = useAxiosGet(endpoints.program.enrolled);
    if (loading) {
        return <Loader />;
    }

    const ongoingPrograms = response.filter(
        (program) =>
            program.Number_Of_EnrolledCourses_Completed !==
            program.Number_Of_EnrolledCourses
    );

    const completedPrograms = response.filter(
        (program) =>
            program.Number_Of_EnrolledCourses_Completed ===
            program.Number_Of_EnrolledCourses
    );

    let programList = response;

    if (filterBy === 1) {
        programList = ongoingPrograms;
    }
    if (filterBy === 2) {
        programList = completedPrograms;
    }

    return (
        <Div>
            <Div>My Programs</Div>

            <Div my='5' borderRadius='3' py='2' bg='white' width='max-content'>
                <Span
                    cursor='pointer'
                    py='2'
                    px='3'
                    borderRadius='3'
                    border={filterBy === null ? '1px solid' : 'none'}
                    onClick={() => setFilterBy(null)}
                >
                    All
                </Span>
                <Span
                    cursor='pointer'
                    py='2'
                    px='3'
                    borderRadius='3'
                    border={filterBy === 1 ? '1px solid' : 'none'}
                    onClick={() => setFilterBy(1)}
                >
                    Ongoing Programs
                </Span>
                <Span
                    cursor='pointer'
                    py='2'
                    px='3'
                    borderRadius='3'
                    border={filterBy === 2 ? '1px solid' : 'none'}
                    onClick={() => setFilterBy(2)}
                >
                    Completed Programs
                </Span>
            </Div>

            <Flex mb='5'>
                {programList.map((program) => {
                    return (
                        <Div
                            width={{ lg: 1 / 4, md: 1 / 3, sm: 1 / 2 }}
                            pr='3'
                            key={program.Course_Program_ID}
                        >
                            {/* {console.log(program.Course_Category_ID)} */}
                            <EnrolledCard
                                title={program.Course_Program_NAME}
                                completionStatus={`
                                        ${program.Number_Of_EnrolledCourses_Completed} /
                                            ${program.Number_Of_EnrolledCourses} 
                                            Courses completed 
                                    `}
                                progress={Math.floor(
                                    (program.Number_Of_EnrolledCourses_Completed /
                                        program.Number_Of_EnrolledCourses) *
                                        100
                                )}
                                credits={program.Course_Program_Credits}
                                onClick={() => {
                                    navigate('/program-overview-enrolled', {
                                        state: {
                                            id: program.Course_Program_ID,
                                            categoryId:
                                                program.Course_Category_ID,
                                        },
                                    });
                                }}
                            />
                        </Div>
                    );
                })}
            </Flex>
        </Div>
    );
}

export default AllEnrolledPrograms;

import React, { useState } from 'react';
import { Flex, Span } from '../../style/styledElements/StyledElements';
import Dropdown from '../Dropdown';
import { ReactComponent as Bell } from '../../asset/icons/Bell.svg';
import styled from 'styled-components';
import Menu from './Menu';
import axios from 'axios';
import endpoints from '../../endpoints';
import { toast, Toaster } from 'react-hot-toast';
import { getErrorMsg } from '../../helpers/getMsgFromErrorObject';

const NotifyIcon = styled(Span)`
    position: relative;
    display: flex;
    &.active:before {
        content: '';
        width: 6px;
        height: 6px;
        background: #fef0d0;
        position: absolute;
        right: 0;
        border-radius: 50%;
        top: -5px;
        border: 2px solid #eba90d;
        z-index: 1;
    }
`;

function Notification({ notifications, refresh }) {
    const [listType, setListType] = useState('unread');

    const unread = notifications.filter(
        (notification) => Number(notification.Read_Status) !== 1
    );

    const visibleNotifications = listType === 'unread' ? unread : notifications;

    const readNotification = (body) => {

        axios
            .post(endpoints.notification.read, {
                NOTIFICATION_ID: body.NOTIFICATION_ID,
            })
            .then((res) => {
                refresh();
            })
            .catch((err) => {
                toast.error(getErrorMsg(err));
            });
    };

    return (
        <>
            <Dropdown
                ml='auto'
                mr='6'
                menuWidth='25vw'
                Toggle={
                    <Flex
                        border='1px solid #EFEEEE'
                        height='50px'
                        width='50px'
                        center
                        borderRadius='4'
                        boxShadow='5px 6px 7px rgb(41 72 123 / 15%)'
                    >
                        <NotifyIcon
                            zIndex='2'
                            className={unread.length ? 'active' : ''}
                        >
                            <Bell />
                        </NotifyIcon>
                    </Flex>
                }
            >
                {(closeDropdown) => (
                    <Menu
                        list={visibleNotifications}
                        setListType={setListType}
                        listType={listType}
                        closeDropdown={closeDropdown}
                        readNotification={readNotification}
                    />
                )}
            </Dropdown>
            <Toaster />
        </>
    );
}

export default Notification;

import React, { useState } from "react";
import Heading from "../components/Heading";
import ImageComponent from "../components/ImageComponent";
import ScrollDiv from '../components/Scroll';
import { Div } from "../../../style/styledElements/StyledElements";

const StudyMaterials = ({ direction = 'column' }) => {

  const [column, setColumn] = useState(direction === 'column');
  const steps = [
    {
      id: 28,
      para: 'After the Course is Enrolled, Participant can Access the Study Materials of the Course in the Highlighted portion.',
      imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-25/5d223183-609c-4846-bb03-ae2567e425a2/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0"
    },
    {
      id: 29,
      para: 'Participant can Watch the Video Tutorials of the Course by Clicking in the Highlighted portion.',
      imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-25/a82175d5-684e-452c-a1c5-b2c2b0fa74e4/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0"
    },
    {
      id: 30,
      para: 'To Read the Text Documents of the Course,Click on the Highlighted portion.',
      imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-25/f6441f2c-fbb0-4784-825a-5d2138fe608b/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0"
    },
    {
      id: 31,
      para: 'Participant can Attend Live Classes by Clicking on the Highlighted portion.',
      imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-25/6afb746f-fcf6-41d8-ae33-d2cd077b7690/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0"
    },
    {
      id: 32,
      para: 'Once the Instructor has Scheduled a Live Class it will Appear in the "Un-Registered Classes". Click on "Enroll now" to Enroll the Live Class.',
      imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-25/29c79cfd-bab0-451d-b82e-ac5f784cbe48/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1893,878&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=133,238"
    },
    {
      id: 33,
      para: 'Participant is Successfully Enrolled to the Live class.',
      imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-13/323281b4-bbe9-430b-9c76-a22852f40d0e/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1901,882&force_format=png&width=1120.0"
    },
    {
      id: 34,
      para: 'When the Live Class Starts a Join Option will be Available to Join the Class, Click on "Join now" to Join the Live Class.',
      imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-25/326ab50b-b11e-4f97-bfac-bd882bdd377b/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1903,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=141,235"
    },
    {
      id: 35,
      para: 'Participant is Navigated to the Live Class Page.',
      imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-27/30dd931c-40a5-4f68-8ea8-6bf22534197c/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=463,235"
    },
    {
      id: 36,
      para: 'Once a live class is completed a Popup is displayed "All live classes are completed".',
      imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-06/e4b64257-02b8-44a4-bf4c-e1674727b9b4/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0"
    },
    {
      id: 37,
      para: 'By Clicking on the Highlighted portion Participant can Start the Quiz.',
      imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-06/3dbba497-bf5a-4e95-9c26-ec3ff18e248d/File.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0"
    },
    {
      id: 38,
      para: 'Click on the "Start Quiz" button to Attend the Quiz.',
      imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-11/bb456e05-bd49-4d60-b1ee-452b2b6b1f07/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=672,140"
    },
    {
      id: 39,
      para: 'On Clicking "Start Quiz" the Timer Begins and Participant can Choose the Options for each Questions.',
      imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-11/050bd10a-167a-429a-9f46-3e5b5cc83653/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=95,216"
    },
    {
      id: 40,
      para: 'Click on "Next" to Get the Next Question.',
      imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-11/c928912c-5da5-4baa-8064-0591ee43df40/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=326,395"
    },
    {
      id: 41,
      para: 'Click on "Submit" to Submit the Answers.',
      imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-11/db222351-0d50-4c47-8007-9f8b01a7b3ae/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=342,405"
    },
    {
      id: 42,
      para: 'After the Submission of Quiz, Participant can see the Quiz Results.',
      imageUrl: "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-27/4b78ada2-2bea-4fb1-a75c-36308704b6d7/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0"
    }
  ];

  return (
    <Div bg={column && '#F3F2F2'} width='95%' borderRadius='32px' pb='6'>
      <Div display={direction !== 'row' && 'none'} >
        <Heading text='Study Materials' numberOfScreens={steps.length} />
      </Div>      <ScrollDiv>
        <div className='scroll' style={{ display: 'flex', flexDirection: direction, overflowX: 'auto', alignItems: 'center' }}>
          <Div py='4' width='60%' display={direction === 'row' && 'none'}>
            <Heading mb='0' text='Study Materials' numberOfScreens={steps.length} />
          </Div>
          {steps.map(step => (
            <Div py='4' key={step.id} style={{ marginRight: '20px', width: '60%' }}>
              <ImageComponent imageUrl={step.imageUrl} direction={direction} desc={step.para} />
            </Div>
          ))}
        </div>
      </ScrollDiv>
    </Div>
  );
};

export default StudyMaterials;

import styled from 'styled-components';
import {
    Div,
    Link,
    Span,
} from '../../style/styledElements/StyledElements';

const DropdownItem = styled(Link)`
    display: block;
    text-align: justify;
    font-weight: 500;
    :hover {
        background: white;
        color: initial;
    }
    overflow-wrap: anywhere;
    color: black;
    padding: 12px;
    border: 1px solid lightgray;
    border-bottom: none;
`;
const TabButton = styled(Span)`
    padding: 4px 0;
    width: 80px;
    display: inline-block;
    border: 1px solid #eba90d;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
`;

function Menu({
    list,
    setListType,
    listType,
    closeDropdown,
    readNotification,
}) {
    return (
        <Div>
            <Div textAlign='center' mb='4'>
                <TabButton
                    borderTopLeftRadius='5'
                    borderBottomLeftRadius='5'
                    bg={listType === 'unread' ? '#fef0d0' : 'white'}
                    onClick={() => setListType('unread')}
                >
                    Unread
                </TabButton>
                <TabButton
                    borderTopRightRadius='5'
                    borderBottomRightRadius='5'
                    bg={listType === 'all' ? '#fef0d0' : 'white'}
                    onClick={() => setListType('all')}
                >
                    All
                </TabButton>
            </Div>
            <Div maxHeight='300px' overflowY='auto'>
                {list.length ? (
                    list.map((message) => (
                        <DropdownItem
                            to={'course-overview-enrolled'}
                            state={{
                                id: message.Course_ID,
                                selectedStructure: {
                                    id: message.Course_Structure_ID,
                                    type: 'live',
                                },
                            }}
                            key={message.NOTIFICATION_ID}
                            fontSize='2'
                            onClick={() => {
                                readNotification(message);
                                closeDropdown();
                            }}
                            bg={
                                Number(message.MARK_READ === 1)
                                    ? 'rgb(240, 240, 240)'
                                    : 'white'
                            }
                        >
                            {message.NOTIFICATION_CONTENT}
                         </DropdownItem>
                    ))
                ) : (
                    <Div
                        textAlign='center'
                        my='4'
                        fontSize='5'
                        fontWeight='500'
                        color='gray'
                    >
                        Nothing to show here
                    </Div>
                )}
            </Div>
        </Div>
    );
}

export default Menu;

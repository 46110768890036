import React from 'react';
import { Div, Flex } from '../../style/styledElements/StyledElements';
import { useNavigate } from 'react-router-dom';
import EnrolledCard from '../../components/EnrolledCard';

function OngoingCourses({ courses }) {
    const navigate = useNavigate();

    const ongoingCourses = courses?.filter(
        (course) => course.Course_items_number !== course.Course_Items_Completed
    );
    return (
        <Div mt='-30px'>
            <Div fontWeight='600' mb='2' textAlign='center' color='gray'>
                Ongoing Courses
            </Div>
            {ongoingCourses?.length === 0 ? (
                <Flex
                    center
                    border='8px solid white'
                    borderRadius='6'
                    height='250px'
                >
                    <Div textAlign='center'>
                        <Div color='#CE4444' fontSize='4' mb='3'>
                            Currently no Ongoing courses
                        </Div>
                    </Div>
                </Flex>
            ) : (
                <Flex maxHeight='75vh' overflowY='auto'>
                    {ongoingCourses?.map((course) => {
                        const progress = Math.floor(
                            (course.Course_Items_Completed /
                                course.Course_items_number) *
                                100
                        );
                        return (
                            <EnrolledCard
                                key={course.Course_ID}
                                title={course.Course_NAME}
                                completionStatus={`
                                    ${course.Course_Items_Completed} /
                                    ${course.Course_items_number}
                                    Tasks completed
                                `}
                                mb='3'
                                credits={course.Course_Credits}
                                progress={progress}
                                onClick={() => {
                                    navigate('/course-overview-enrolled', {
                                        state: {
                                            id: course.Course_ID,
                                        },
                                    });
                                }}
                            />
                        );
                    })}
                </Flex>
            )}
        </Div>
    );
}

export default OngoingCourses;

const styles = {
    card: {
        mb: '3',
        p: '3',
        bg: 'white',
        borderRadius: '5',
        boxShadow: '0px 3px 8px rgba(74, 85, 104, 0.07)',
        alignItems: 'center',
        width: '50%',
    },
    avatar: {
        width: '65px',
        height: '65px',
        borderRadius: '5',
    },
};

import { useEffect, useRef, useState } from 'react';
import { Div } from '../style/styledElements/StyledElements';

function Dropdown({ Toggle, children, menuWidth, ...rest }) {
    const [showDropdown, setDropdown] = useState(false);

    const menuRef = useRef(null);
    const toggleRef = useRef(null);

    useEffect(() => {
        const handler = (event) => {
            if (
                showDropdown &&
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                toggleRef.current &&
                !toggleRef.current.contains(event.target)
            ) {
                setDropdown(false);
            }
        };
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, [showDropdown]);

    return (
        <Div position='relative' {...rest}>
            <Div
                cursor='pointer'
                onClick={() => setDropdown((prev) => !prev)}
                ref={toggleRef}
            >
                {Toggle}
            </Div>
            <Div
                position='absolute'
                top='100%'
                right='0'
                borderRadius='6'
                borderTopRightRadius='0'
                p='5'
                display={showDropdown ? 'block' : 'none'}
                width={menuWidth || '10vw'}
                m='0'
                ref={menuRef}
                bg='white'
                border='1px solid #EFEEEE'
                boxShadow='5px 6px 7px rgb(41 72 123 / 15%)'
            >
                {children(() => setDropdown(false))}
            </Div>
        </Div>
    );
}

export default Dropdown;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import './index.css';
import reportWebVitals from './reportWebVitals';
import theme from './style/theme';
import GenerateRoutes from './routing/GenerateRoutes';
import { AuthenticationProvider } from './context/AuthContext';
// import { SearchProvider } from './context/SearchContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <AuthenticationProvider>
            <ThemeProvider theme={theme}>
                {/* <SearchProvider> */}
                <GenerateRoutes />
                {/* </SearchProvider> */}
            </ThemeProvider>
        </AuthenticationProvider>
    </BrowserRouter>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

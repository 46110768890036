import React, { useState } from "react";
import ImageComponent from "../components/ImageComponent";
import Heading from "../components/Heading";
import ScrollDiv from '../components/Scroll';
import { Div } from "../../../style/styledElements/StyledElements";

export const Program = ({ direction = 'column' }) => {

    const [column, setColumn] = useState(direction === 'column');
    const steps = [
        {
            id: 49,
            para: 'By Clicking on the "Programs" from the sidebar, Participant is Navigate to the Program List Page.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-06/b0c7fb04-079f-4b47-b746-db3ce78e3ff4/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0'
        },
        {
            id: 50,
            para: 'Here Participant can See the List of Programs.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-25/54b0db1b-091e-440c-aa6f-7a9dfff2ca73/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0'
        },
        {
            id: 51,
            para: 'Participant can select a Program to Enroll and view the Program Details.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-27/3da1275e-ebfd-40f1-8f7f-9ea9beeb8241/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=392,108'
        },
        {
            id: 52,
            para: 'Participant is Navigate to Program Overview Page and can See the Program Details. By Clicking on "Enroll Now" Participant is Enroll to the Program.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-11/24a9f05d-2763-46b3-8319-535312ae9cb9/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=684,192'
        },
        {
            id: 53,
            para: 'Here we can See the List of Courses Inside the Program.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-27/98804b37-1802-4ab8-88f7-2486f2593b87/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=876,128'
        },
        {
            id: 54,
            para: 'By Clicking on the Course, Participant is navigate to the Course Details page.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-11/af24e8f0-f6d2-4c02-942d-ddf1a0bf6dbf/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=878,148'
        },
        {
            id: 55,
            para: 'Participant can Access the Study Materials in the Selected Course.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-25/3cc546a3-72e7-4524-9897-aab0734c654a/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0'
        },
        {
            id: 56,
            para: 'On Completion of Courses the Status of the Course Changes to "Completed"(100%).',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-06/0b446334-dd10-43bd-b0ce-61549b19775e/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=209,117'
        },
        {
            id: 57,
            para: 'Click on "Get Certificate" to get the Program Completion Certificate.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-06/b404275a-c251-48de-8a4a-2cc9e282a91f/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=670,203'
        },
        {
            id: 58,
            para: 'Program Status Changed to Complete(100%).',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-11/4701731d-4f6c-4e1e-8c1e-e8cd4af27676/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=206,112'
        },
        {
            id: 59,
            para: 'Participant can Search the Required Courses using Search bar.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-11/ba70c470-90ff-49cf-b3a3-e85ae4ef7231/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=140,-2'
        },
        {
            id: 60,
            para: 'Click the Icon to toggle to program.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-11/aabb9883-6652-42ef-9fda-15a0ac7bd4e1/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=363,-5'
        },
        {
            id: 61,
            para: 'Search the Required Program.',
            imageUrl: 'https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-09-11/400fce0c-534d-4d23-a6e6-0759161a3b3d/ascreenshot.jpeg?tl_px=0,0&br_px=1920,884&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=189,-15'
        },
    ];

    return (
        <Div bg={column && '#F3F2F2'} width='95%' borderRadius='32px' pb='6'>
            <Div display={direction !== 'row' && 'none'} >
                <Heading text='Programs' numberOfScreens={steps.length} />
            </Div>
            <ScrollDiv>
                <div className='scroll' style={{ display: 'flex', flexDirection: direction, overflowX: 'auto', alignItems: 'center' }}>
                    <Div py='4' width='60%' display={direction === 'row' && 'none'}>
                        <Heading mb='0' text='Programs' numberOfScreens={steps.length} />
                    </Div>
                    {steps.map(step => (
                        <Div py='4' key={step.id} style={{ marginRight: '20px', width: '60%' }}>
                            <ImageComponent imageUrl={step.imageUrl} direction={direction} desc={step.para} />
                        </Div>
                    ))}
                </div>
            </ScrollDiv>
        </Div>
    );
};

export default Program;

import React from 'react';
import { Div, Flex, Link } from '../style/styledElements/StyledElements';
import { ReactComponent as Dashboard } from '../asset/icons/Dashboard.svg';
import { ReactComponent as Schedule } from '../asset/icons/Schedule.svg';
import { ReactComponent as Programs } from '../asset/icons/Programs.svg';
import { ReactComponent as Course } from '../asset/icons/Course.svg';
import { ReactComponent as HelpIcon } from '../asset/icons/HelpVector.svg'
import { useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../asset/icons/Logo.svg';

const links = [
    {
        path: '/',
        name: 'Dashboard',
        icon: <Dashboard />,
    },
    {
        path: '/schedule-calendar',
        name: 'Schedule',
        icon: <Schedule />,
    },
    {
        path: '/course',
        name: 'Course',
        icon: <Course />,
    },
    {
        path: '/programs',
        name: 'Programs',
        icon: <Programs />,
    },

];
function Sidebar() {
    const { pathname } = useLocation();
    return (
        <Div
            height='100vh'
            width='100px'
            boxShadow='0px 4px 4px rgba(0, 0, 0, 0.1)'
            fontSize={1}
            overflow='auto'
            zIndex='1000'
            bg='white'
            position='fixed'
            top='0'
            left='0'
        >
            <Flex height='80px' center>
                <Logo />
            </Flex>

            <Div mt='100px' textAlign='center'>
                {links.map((link) => (
                    <Link
                        mb='4'
                        display='block'
                        color={
                            pathname === link.path ? 'blues.primary' : 'black'
                        }
                        to={link.path}
                        key={link.name}
                    >
                        {link.icon}
                        <Div>{link.name}</Div>
                    </Link>
                ))}

            </Div>

            <Div mt='237px' pt='4' borderTop='2px solid rgba(0, 0, 0, 0.11)' textAlign='center'>

                <Link
                    mb='4'
                    display='block'
                    color={
                        pathname === 'help' ? 'blues.primary' : '#818181'
                    }
                    to={'/help'}
                    key={'User Guide'}
                >
                    <HelpIcon />
                    <Div>{'User Guide'}</Div>
                </Link>

            </Div>

        </Div>
    );
}

export default Sidebar;

import React from 'react';
import coursedDefaultImg from '../asset/images/course-without.png';
import { Div, Img } from '../style/styledElements/StyledElements';

function Coursecard({ dp, children, bg }) {
    const DPIMG = checkImgURL(dp || '') ? dp : coursedDefaultImg;

    function checkImgURL(dp) {
        return dp.match(/\.(jpeg|jpg|png)$/) != null;
    }
    // console.log(dp)
    return (
        <div
            style={{
                boxShadow: '0px 3px 8px rgba(74, 85, 104, 0.07)',
                backgroundColor: bg,
                overflow: 'hidden',
                borderRadius: '20px',
            }}
        >
            <div
                style={{
                    paddingBottom: '25px',
                    paddingTop: dp ? '' : '20px',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Div
                    backgroundImage={'url(' + DPIMG + ')'}
                    backgroundPosition='center'
                    backgroundSize='cover'
                    height={dp ? '220px' : '200px'}
                    maxHeight={dp ? '220px' : '200px'}
                    width='100%'
                />
            </div>
            <div
                style={{
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    paddingBottom: '8px',
                }}
            >
                {children}
            </div>
        </div>
    );
}

export default Coursecard;

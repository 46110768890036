import React, { useEffect } from 'react';
import { ReactComponent as Logo } from '../../asset/icons/userGuide/ShLogo_UserGuide.svg';
import { ReactComponent as ArrowLeft } from '../../asset/icons/userGuide/ArrowLeft.svg';
import { Div, Flex } from '../../style/styledElements/StyledElements';
import Sidebar from './components/Sidebar';
import SearchBar from './components/SearchBar';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

const HelpLayout = () => {
    const navigate = useNavigate();
    const mainContent = React.useRef(null);
    const location = useLocation();
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    return (
        <>
            <Flex
                top='0'
                width='100%'
                alignItems='center'
                pr='3%'
                py='2%'
                bg='cream'
                zIndex='1000'
                position='fixed'
            >
                <Div
                    pl='2%'
                    pr='2'
                    onClick={() => navigate('/')}
                    cursor='pointer'>
                    <ArrowLeft />
                </Div>
                <Logo />
                <Div px='2' fontWeight='400' fontSize='35px'>User Guide</Div>

            </Flex>

            <Div><Sidebar /></Div>
            <Div><SearchBar /></Div>
            <Div mt='13%'>
                <Div ml='17%' pb='6' pl='5' ref={mainContent} color='black'>
                    <Outlet />
                </Div>
            </Div>
        </>
    );
};


export default HelpLayout;

import React from 'react';
import { Div, Flex, Span } from '../../style/styledElements/StyledElements';
import { ReactComponent as Graduate } from '../../asset/icons/dashboard/GraduateWhite.svg';
import { ReactComponent as Notepad } from '../../asset/icons/dashboard/NotePadWhite.svg';

function Enrolled({ myCourseCount, myProgramCount }) {
    return (
        <Flex justifyContent='space-between'>
            <Flex {...styles.card} bg='#DAF0F6'>
                <Flex bg='#47B6D4' {...styles.avatar} center>
                    <Graduate />
                </Flex>
                <Div>
                    <Span fontWeight='600'>{myCourseCount}</Span>
                    <br />
                    <Span color='#686868' fontSize='2'>
                        My Courses
                    </Span>
                </Div>
            </Flex>
            <Flex bg='navy.light' {...styles.card}>
                <Flex bg='navy.primary' center {...styles.avatar}>
                    <Notepad />
                </Flex>
                <Div>
                    <Span fontWeight='600'>{myProgramCount}</Span>
                    <br />
                    <Span color='#686868' fontSize='2'>
                        My Programs
                    </Span>
                </Div>
            </Flex>
        </Flex>
    );
}

export default Enrolled;

const styles = {
    card: {
        flexDirection: 'column',
        height: '170px',
        borderRadius: '6',
        p: '4',
        width: '47%',
        justifyContent: 'space-between',
    },
    avatar: {
        height: '40px',
        width: '40px',
        borderRadius: '50%',
    },
};
